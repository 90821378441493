export default {

    version: '10012',
    language: 'de',

    googleApi: 'AIzaSyBoGEsyYZ30VYxAm7j0vQFS1kD43s-WhlM',

    token: null,
    userId: null,
    userGroupId: null,
    userIdent: null,
    dashboardReportId: null,

    menu: null,

    // host: document.location.origin.contains('localhost') ? 'http://skip/': '/',
    // host: 'http://iabo/',
    // apiHost: 'http://iabo/api/'

    host: 'https://v2.iabo.eu/',
    apiHost: 'https://v2.iabo.eu/api/'
};

